// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import {
  SEO,
  Title,
  Text,
  Intro,
  Map,
  ReadMore,
  ContactForm,
} from "components";

const pageProperties = {
  title: "Quando vieni a trovarci?",
  //description: "Tranquillo. Mal che vada ti offriremo un caffè e ci faremo un gin tonic, o viceversa :)",
};

function ContattaciPage() {
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contatti e riferimenti @ Prato"
        description="Passaci a trovare. Mal che vada ti offriremo un caffè e ci faremo un gin tonic, o viceversa :)"
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="">
        <section className="px-8 md:px-64 flex flex-col justify-between contact-form mt-12 mb-24">
          <div className="flex flex-col md:flex-row mt-24 md:mt-48">
            <div className="md:w-1/2">
              <Title>Quando vieni a trovarci?</Title>
              <div className="mt-12">
                <Text>
                  Siamo in{" "}
                  <strong>Via Frà Bartolomeo 82/A, a Prato (PO)</strong>
                </Text>
                <Text>
                  ma ci puoi trovare anche a{" "}
                  <strong>Venezia (VE), in via Torino 186</strong>
                </Text>
                <Text>
                  oppure chiamarci al <strong>0574 020861</strong>
                </Text>
                <div className="h-96 mt-12 w-full relative">
                  <Map />
                  <ReadMore transparent>
                    <a
                      href="https://g.page/tramedigitali?share"
                      target="_blank"
                    >
                      Vai alle indicazioni su Google Map
                    </a>
                  </ReadMore>
                </div>
              </div>
            </div>
            <ContactForm action="https://www.flexyform.com/f/94dbc0d5050826f9751de15c20803ff096d4cccf" />
          </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default ContattaciPage;
